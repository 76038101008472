import React from 'react';
import { FaPencilRuler } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const uiuxImage = "https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149052117.jpg";

const UiUxDesign = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaPencilRuler className="service-icon" />
        <h3>UI/UX Design</h3>
      </div>
      <img src={uiuxImage} alt="UI/UX Design" className="service-image" />
      <div className="service-content">
        <p>
          We specialize in crafting visually stunning, user-centric designs that offer seamless user experiences. Our design process revolves around understanding the needs of your users and translating them into intuitive, easy-to-use interfaces.
        </p>
        <p>
          <strong>Services We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>User Interface (UI) Design</li>
          <li>User Experience (UX) Research & Strategy</li>
          <li>Wireframing & Prototyping</li>
          <li>Usability Testing & Optimization</li>
          <li>Responsive Web & Mobile Design</li>
          <li>Interaction Design & Microinteractions</li>
          <li>Design Systems & Style Guides</li>
        </ul>
        <p>
          Our goal is to create designs that not only look beautiful but also enhance usability and engagement. By integrating modern design trends with usability principles, we help you deliver an exceptional digital experience.
        </p>
        <p>
          Whether you're building a new product or redesigning an existing one, our team ensures that your design is both aesthetically pleasing and highly functional.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default UiUxDesign;
