import React from 'react';
import { FaCode } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';
const webDevImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcHGvYy_RkCMFDGflcKbJSPinoLb_5nuQXxQ&s";

const WebDev = () => {

  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaCode className="service-icon" />
        <h3>Web Development</h3>
      </div>
      <img src={webDevImage} alt="Web Development" className="service-image" />
      <div className="service-content">
        <p>
          At CodeHub, we specialize in crafting modern, responsive websites that deliver seamless user experiences across devices. Our expert developers ensure your vision is brought to life with robust and scalable web solutions.
        </p>
        <p>
          <strong>Services We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>Custom Website Development</li>
          <li>Responsive Design & Optimization</li>
          <li>E-commerce Platforms</li>
          <li>Web Application Development</li>
          <li>Content Management Systems (CMS)</li>
          <li>SEO Optimization & Analytics</li>
          <li>Website Maintenance & Support</li>
        </ul>
        <p>
          We employ the latest technologies such as <strong>React, Node.js, Angular, and more</strong> to ensure your website is fast, secure, and optimized for search engines. From concept to launch, our team handles every stage of development, including UI/UX design, front-end, back-end, and database management.
        </p>
        <p>
          Ready to take your online presence to the next level? Let us help you build a website that not only looks great but drives business results.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default WebDev;
