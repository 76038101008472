import React, { useEffect, useState } from 'react';
import '../Styling/Portfolio.css';
import LoadingSpinner from '../Data/LoadingSpinner';
import Footer from './Footer';

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  // Dummy project data
  const dummyProjects = [
    {
      id: 1,
      title: 'E-Commerce Platform',
      imageUrl: 'https://img.freepik.com/free-photo/cyber-monday-shopping-sales_23-2148688502.jpg?t=st=1731878332~exp=1731881932~hmac=7e43f076b16a42390fca971937aff59dcd7eed7f916cc4a2f208906ff0761000&w=740',
      description:
        'A modern e-commerce platform with features like payment integration, user authentication, and responsive design.',
    },
    {
      id: 2,
      title: 'Portfolio Website',
      imageUrl: 'https://img.freepik.com/free-vector/ecommerce-checkout-laptop-concept-illustration_114360-8243.jpg?t=st=1731878451~exp=1731882051~hmac=c95857a728083070b649fe9162b8ce6c05e93028c2f5f742cf4af792bb6827b0&w=740',
      description:
        'A personal portfolio showcasing projects, blogs, and social media integrations.',
    },
    {
      id: 3,
      title: 'Task Management App',
      imageUrl: 'https://img.freepik.com/free-vector/app-development-concept-with-flat-deisng_23-2147851632.jpg?t=st=1731878493~exp=1731882093~hmac=6f56299d35261bfef1547f00f20832647b84b921946008bf0754fac8284082b7&w=740',
      description:
        'A task management app with features like drag-and-drop, notifications, and cross-platform support.',
    },
    {
      id: 4,
      title: 'Blog CMS',
      imageUrl: 'https://img.freepik.com/free-photo/technology-communication-icons-symbols-concept_53876-120314.jpg?t=st=1731878528~exp=1731882128~hmac=690e8c482983e529e7bbe2b22aae4c34a9d2f8ba8e49c9290487d69fb38eded9&w=740',
      description:
        'A content management system for bloggers to create, edit, and publish posts easily.',
    },
    {
      id: 5,
      title: 'Fitness Tracker',
      imageUrl: 'https://img.freepik.com/free-vector/smartwatch-concept-illustration_114360-4306.jpg?t=st=1731878562~exp=1731882162~hmac=eec7bc2f746ea18b86dfa34f2debd328b8c7ac428cad99b908adab25f4f20091&w=740',
      description:
        'A fitness tracking app that monitors workouts, calories, and goals.',
    },
    {
      id: 6,
      title: 'Social Media Dashboard',
      imageUrl: 'https://img.freepik.com/free-vector/dashboard-user-panel-template_23-2148275813.jpg?t=st=1731878591~exp=1731882191~hmac=5070f3368dd6e48a6b6fb4c109b0660479b7c53f2d1777af91f903c00a958c42&w=740 ',
      description:
        'A dashboard for managing multiple social media accounts and analyzing engagement.',
    },
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // Simulate an API call with dummy data
        const fetchedProjects = await new Promise((resolve) => {
          setTimeout(() => resolve(dummyProjects), 1000);
        });
        setProjects(fetchedProjects);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setLoading(false);
      }
    };

    fetchProjects();
  },);

  if (loading) {
    return (
      <div className="loading">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="portfolio-container">
        <h1 className="portfolio-header">Our Projects</h1>
        <div className="portfolio-grid">
          {projects.map((project) => (
            <div key={project.id} className="portfolio-card">
              <div className="card-image-container">
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className="portfolio-image"
                />
              </div>
              <div className="portfolio-content">
                <h2 className="portfolio-title">{project.title}</h2>
                <p className="portfolio-description">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Portfolio;
