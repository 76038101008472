import React from "react";
import '../Styling/Footer.css'
import {
    FaLinkedin,
    FaGithub,
    FaEnvelope,
    FaPhone,
    FaWhatsapp,
  } from "react-icons/fa";
const Footer =()=>{
    const specialText = {
        special: "<Anas Saleem/>",
        codeHub: "<CodeHub/>",
      };
    return(
<>
<footer className="footer fade-in-animation">
<div className="container">
  <div className="footer-grid">
    {/* Company Info */}
    <div className="footer-section company-info">
      <h3>About Us</h3>
      <p>
        We are a team of passionate developers and designers building
        amazing digital experiences.
      </p>
      <p>© 2024 <span style={{color:"#10b981"}}>{specialText.codeHub}</span>. All rights reserved.</p>
    </div>

    {/* Contact Info */}
    <div className="footer-section contact-info">
      <h3>Contact Us</h3>
      <ul>
        <li>
          <FaEnvelope />{" "}
          <a href="mailto:anassaleem063@gmail.com">CodeHub Email</a>
        </li>
        <li>
          <FaPhone /> <a href="tel:+923161971274">CodeHub Phone</a>
        </li>
      </ul>
    </div>

    {/* Social Media */}
    <div className="footer-section social-media">
      <h3>Follow Us</h3>
      <div className="social-icon">
        <a href="https://www.linkedin.com/in/dailycode01/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href="https://github.com/awananas929" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
        <a href="https://wa.me/+923161971274" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
      </div>
    </div>
  </div>
</div>
</footer>
</>
    )
}
export default Footer;