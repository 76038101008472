import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavMenu from './Components/NavMenu';
import Home from './Components/Home';
import About from './Components/About';
import Portfolio from './Components/Portfolio';
import Contact from './Components/Contact';
import Services from './Components/Services';
import Spinner from './Data/LoadingSpinner';
import WebDev from './Services/WebDev'
import AppDev from './Services/AppDev';
import GameDev from './Services/GameDev'
import DigitalMarketing from './Services/DigitalMarketing'
import GraphicDesign from './Services/GraphicDesign'
import SEO from './Services/SEO'
import VideoEditing from './Services/VideoEditing'
import UiUx from './Services/UiUxDesign'
import ServiceSelection from './Components/ServiceSelection';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (

    <div className="app-wrapper">
      <Router>
      <NavMenu/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/WebDev" element={<WebDev />} />
          <Route path="/AppDev" element={<AppDev />} />
          <Route path="/GameDev" element={<GameDev />} />
          <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
          <Route path="/GraphicDesign" element={<GraphicDesign />} />
          <Route path="/seo" element={<SEO />} />
          <Route path="/VideoEditing" element={<VideoEditing />} />
          <Route path="/UiUx" element={<UiUx />} />
          <Route path="/ServiceSelection" element={<ServiceSelection />} />
        </Routes>
      </Router>
      </div>
   
  );
}

export default App;
