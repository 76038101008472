import React from 'react';
import { FaMobileAlt } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const appDevImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPKiQVdWWUbbMybbulfUUvGfe1I865qtMu5Q&s";

const AppDev = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaMobileAlt className="service-icon" />
        <h3>App Development</h3>
      </div>
      <img src={appDevImage} alt="App Development" className="service-image" />
      <div className="service-content">
        <p>
          We develop cutting-edge mobile applications tailored to your business needs. Our team excels in creating fast, responsive, and user-friendly apps that provide great performance across iOS and Android devices.
        </p>
        <p>
          <strong>Services We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>Native App Development (iOS & Android)</li>
          <li>Cross-platform App Development (React Native, Flutter)</li>
          <li>User Interface (UI) & User Experience (UX) Design</li>
          <li>App Testing & Quality Assurance</li>
          <li>App Maintenance & Updates</li>
          <li>App Store Deployment & Optimization</li>
        </ul>
        <p>
          Our experienced developers use the latest tools and frameworks to deliver apps that are not only visually appealing but also highly functional, scalable, and secure.
        </p>
        <p>
          From initial concept to post-launch maintenance, we offer a complete app development cycle, ensuring that your app meets the highest standards of quality.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default AppDev;
