import React from 'react';
import { FaGamepad } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const gameDevImage = "https://media.istockphoto.com/id/1269257082/photo/in-the-evening-creative-young-video-game-developer-works-on-a-desktop-computer-with-screen-3d.jpg?s=612x612&w=0&k=20&c=vqk9iIrx2P0YxMej2MWcDeR4y8L5Yw5l_vZluFQ04VI=";

const GameDev = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaGamepad className="service-icon" />
        <h3>Game Development</h3>
      </div>
      <img src={gameDevImage} alt="Game Development" className="service-image" />
      <div className="service-content">
        <p>
          Our game development services bring your vision to life, crafting interactive and immersive experiences for mobile, PC, and console platforms. From initial concept to launch, we specialize in delivering stunning visuals, captivating storylines, and engaging gameplay.
        </p>
        <p>
          <strong>What We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>Full-cycle game development from design to deployment</li>
          <li>2D and 3D game art and animation</li>
          <li>Mobile, PC, and console game development</li>
          <li>Cross-platform compatibility</li>
          <li>Game prototyping and testing</li>
          <li>In-game monetization and app store optimization</li>
          <li>Post-launch support and updates</li>
        </ul>
        <p>
          Whether you're building an action-packed mobile game or a complex multiplayer adventure, our team ensures a smooth development process with regular updates and feedback loops. We aim to make your game engaging and successful in today's competitive market.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default GameDev;
