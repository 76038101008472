const testimonialsData = [
    {
      name: "Jack Carroll",
      country: "Ireland",
      text: "Great work and clear in his communication. Was happy with the final product.",
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    },
    {
      name: "Autodhun Music",
      country: "India",
      text: "This team is incredible and will do an amazing job for any website design & development projects.",
      avatar: "https://randomuser.me/api/portraits/men/2.jpg",
    },
    {
      name: "Kate Cheshire",
      country: "UK",
      text: "Amazing work! I am very happy with the results. I will definitely be using this seller again.",
      avatar: "https://randomuser.me/api/portraits/women/3.jpg",
    },
  ];
  
  export default testimonialsData;
  