import React, { useState } from "react";
import "../Styling/ServiceSelection.css";
import { Link } from "react-router-dom";

const ServiceSelection = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedWorkOption, setSelectedWorkOption] = useState(null);
  const [description, setDescription] = useState("");

  const services = [
    "Web Development",
    "App Development",
    "UI/UX Design",
    "Graphic Design",
    "Video Editing",
    "SEO",
    "Game Development",
    "Digital Marketing",
  ];

  const workOptions = ["Project", "Retainer", "Hourly"];

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const handleWorkOptionClick = (option) => {
    setSelectedWorkOption(option);
  };

  const handleNextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleGoBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div className="service-container">
      {step === 1 && (
        <>
          <h2>Select a Service</h2>
          <div className="service-list">
            {services.map((service) => (
              <button
                key={service}
                className={`service-item ${
                  selectedService === service ? "selected" : ""
                }`}
                onClick={() => handleServiceClick(service)}
              >
                {service}
              </button>
            ))}
          </div>
          <button
            className={`continue-btn ${selectedService ? "" : "disabled"}`}
            onClick={handleNextStep}
            disabled={!selectedService}
          >
            Continue
          </button>
        </>
      )}
      {step === 2 && (
        <div className="service-step-2">
          <h2>How Do You Want to Work?</h2>
          <div className="work-options">
            {workOptions.map((option) => (
              <button
                key={option}
                className={`work-option ${
                  selectedWorkOption === option ? "selected" : ""
                }`}
                onClick={() => handleWorkOptionClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
          <div className="step-navigation">
            <button className="go-back-btn" onClick={handleGoBack}>
              Go Back
            </button>
            <button
              className={`continue-btn ${selectedWorkOption ? "" : "disabled"}`}
              onClick={handleNextStep}
              disabled={!selectedWorkOption}
            >
              Continue
            </button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="service-step-3">
          <h2>Give Description of Your Project</h2>
          <textarea
          required='true'
            className="project-description-textarea"
            placeholder="Describe your project here..."
            value={description}
            onChange={handleDescriptionChange}
          />
          <div className="step-navigation">
            <button className="go-back-btn" onClick={handleGoBack}>
              Go Back
            </button>
            <Link to="/contact" className="continue-btn">Describe</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceSelection;
