import "../Styling/Services.css";
import React, { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
const Services = () => {
  const specialText = {
    special: "<Anas Saleem/>",
    codeHub: "<CodeHub/>",
  };
    // Aos Animation
useEffect(() => {
  AOS.init({
    duration: 1000,
  });
  AOS.refresh();
 
}, []);

  return (
    <>
      <div className="service-container2">
        <div className="service-sidebar2">
          <h2>Our Services</h2>
          <ul data-aos="fade-right">
            <Link to="/WebDev" className="service-item">
              <FaCheckCircle className="icon" /> Web Development
            </Link>
            <Link to="/AppDev" className="service-item">
              <FaCheckCircle className="icon" /> App Development
            </Link>
            <Link to="/UiUx" className="service-item">
              <FaCheckCircle className="icon" /> UI/UX Design
            </Link>
            <Link to="/GraphicDesign" className="service-item">
              <FaCheckCircle className="icon" /> Graphic Design
            </Link>
            <Link to="/VideoEditing" className="service-item">
              <FaCheckCircle className="icon" /> Video Editing
            </Link>
            <Link to="/seo" className="service-item">
              <FaCheckCircle className="icon" /> SEO
            </Link>
            <Link to="/GameDev" className="service-item">
              <FaCheckCircle className="icon" /> Game Development
            </Link>
            <Link to="/DigitalMarketing" className="service-item">
              <FaCheckCircle className="icon" /> Digital Marketing
            </Link>
          </ul>
        </div>

        <div className="service-content" data-aos="fade-left">
          <h1>Service Overview</h1>
          <p className="breadcrumb">Home &gt; Services</p>
          <div className="service-overview">
            <h2>What We Offer</h2>
            <p>
              At {specialText.codeHub}, we offer a comprehensive range of digital services to
              elevate your business. With our expert team, we focus on delivering
              state-of-the-art solutions that meet the unique needs of our clients.
            </p>

            <h3>Web Development</h3>
            <p>
              Our web development team builds responsive and scalable websites using
              the latest technologies. From e-commerce platforms to custom web
              applications, we ensure high-performance and security to keep your
              business running smoothly online.
            </p>

            <h3>App Development</h3>
            <p>
              Whether you're targeting Android, iOS, or both, we develop custom mobile
              apps with an engaging user experience, optimized performance, and modern
              designs. Our agile development process ensures quick delivery and high
              adaptability to market trends.
            </p>

            <h3>UI/UX Design</h3>
            <p>
              Exceptional UI/UX design is at the heart of everything we do. We design
              visually appealing, intuitive, and functional interfaces that enhance user
              engagement. Let us craft experiences that keep your users coming back.
            </p>

            <h3>Graphic Design</h3>
            <p>
              From branding to marketing collateral, our creative designers bring your
              ideas to life. Whether it's logos, brochures, or digital ads, we ensure
              your visual identity stands out and communicates your message effectively.
            </p>

            <h3>Video Editing</h3>
            <p>
              We provide professional video editing services, from promotional videos to
              product showcases. Our expert editors bring out the best in your footage
              with seamless cuts, color grading, and creative transitions.
            </p>

            <h3>SEO (Search Engine Optimization)</h3>
            <p>
              Drive more organic traffic to your website with our SEO strategies. We use
              data-driven insights and proven tactics to improve your search rankings,
              boost visibility, and enhance your overall online presence.
            </p>

            <h3>Game Development</h3>
            <p>
              Whether you're looking to develop an engaging mobile game or an immersive
              desktop experience, our game development team builds captivating games with
              stunning graphics, smooth mechanics, and immersive storytelling.
            </p>

            <h3>Digital Marketing</h3>
            <p>
              Our digital marketing services cover everything from social media management
              to pay-per-click campaigns. We help you reach your target audience, build
              brand awareness, and increase conversions with tailored strategies that
              work for your business.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
