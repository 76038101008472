import React from 'react';
import { FaVideo } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const videoEditingImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRCydjdzNSjZ_Gd-YMeqWXG6k6mrlPxREbLQ&s";

const VideoEditing = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaVideo className="service-icon" />
        <h3>Video Editing</h3>
      </div>
      <img src={videoEditingImage} alt="Video Editing" className="service-image" />
      <div className="service-content">
        <p>
          Our professional video editing services help transform raw footage into compelling stories. Whether you need promotional videos, corporate presentations, or social media content, we ensure polished results.
        </p>
        <p>
          <strong>What We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>Promotional and commercial video editing</li>
          <li>Corporate and event video editing</li>
          <li>YouTube and social media content</li>
          <li>Color correction and grading</li>
          <li>Sound design and audio enhancement</li>
          <li>Motion graphics and visual effects</li>
          <li>Final output in multiple formats for any platform</li>
        </ul>
        <p>
          From concept to final delivery, we ensure your videos captivate audiences and effectively communicate your message, no matter the platform or purpose.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default VideoEditing;
