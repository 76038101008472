import React from 'react';
import { FaPaintBrush } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const graphicDesignImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtf6c161iU5P6-z6wk9zFfcluKwZvrjCsJLw&s";

const GraphicDesign = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaPaintBrush className="service-icon" />
        <h3>Graphic Design</h3>
      </div>
      <img src={graphicDesignImage} alt="Graphic Design" className="service-image" />
      <div className="service-content">
        <p>
          Our graphic design services are crafted to visually communicate your brand's story. We create captivating, high-quality designs that leave a lasting impression on your audience.
        </p>
        <p>
          <strong>Services We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>Logo Design & Branding</li>
          <li>Marketing & Promotional Materials</li>
          <li>Social Media Graphics</li>
          <li>Business Cards & Stationery</li>
          <li>Infographics & Presentations</li>
          <li>Illustrations & Custom Art</li>
          <li>Package Design & Print Design</li>
        </ul>
        <p>
          Whether you're starting a new brand or refreshing an existing one, our team combines creativity and strategy to deliver designs that truly represent your business.
        </p>
        <p>
          Let us help you stand out with designs that resonate with your audience and bring your vision to life.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default GraphicDesign;
