import React, { useEffect, useState } from 'react';
import '../Styling/Contact.css';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaUser, FaPaperPlane, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
import Footer from './Footer';
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  // State for form data and status message
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [status, setStatus] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Please Wait...");
    setPopupVisible(true);

    try {
      // Use the API URL from the .env file
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formData);

      if (response.data.success) {
        setStatus('Thank you for reaching out! We have received your message and will get back to you soon.');
        setPopupType('success');

        setFormData({
          name: '',
          phone: '',
          email: '',
          message: ''
        });
      } else {
        setStatus('Failed to send message.');
        setPopupType('error');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('There was an error. Please try again later.');
      setPopupType('error'); 
    }
  };

  // Close the popup after a few seconds
  const closePopup = () => {
    setPopupVisible(false);
    setStatus('');
  };

  // Aos Animation
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="contact-container">
        <h1>Contact Us</h1>
        <p>If you have any questions or need assistance, feel free to reach out to us through the following contact methods:</p>

        <div className="contact-info" data-aos="fade-left">
          <div className="contact-card">
            <FaPhoneAlt className="contact-icon" />
            <h3>Phone</h3>
            <p>+923161971274 || +923354436918</p>
          </div>

          <div className="contact-card" data-aos="fade-right">
            <FaEnvelope className="contact-icon" />
            <h3>Email</h3>
            <p>anassaleem063@gmail.com</p>
          </div>

          <div className="contact-card" data-aos="fade-up">
            <FaMapMarkerAlt className="contact-icon" />
            <h3>Address</h3>
            <p>Muzaffarabad Azad jammu and Kashmir</p>
          </div>
        </div>

        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group icon-input">
              <FaUser className="input-icon" />
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group icon-input">
              <FaPhoneAlt className="input-icon" />
              <input
                type="phone"
                id="phone"
                name="phone"
                placeholder="Your Phone#"
                required
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
             
            <div className="form-group icon-input">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="form-group icon-input">
              <FaPaperPlane className="input-icon" />
              <textarea
                id="message"
                name="message"
                rows="5"
                placeholder="Your Message"
                required
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>

            <button type="submit" className="submit-btn">Send Message</button>
          </form>
        </div>
      </div>

      <Footer />

      {/* Popup */}
      {popupVisible && (
        <div className={`popup ${popupType}`}>
          <div className="popup-content">
            {popupType === 'success' ? (
              <FaCheckCircle className="popup-icon success-icon" />
            ) : (
              <FaTimesCircle className="popup-icon error-icon" />
            )}
            <p>{status}</p>
            <button className="popup-close-btn" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
