// LoadingSpinner.js
import React from 'react';
import '../Styling/LoadingSpinner.css'

const LoadingSpinner = () => {
  return (
    <div className="new-loader-container">
    <div className="new-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  );
};

export default LoadingSpinner;