import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import "../Styling/Home.css";
import "../Styling/ServiceCard.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaLinkedin,
  FaGithub,
  FaReact,
  FaNodeJs,
  FaDatabase,
  FaWordpress,
  FaShopify,
  FaWix,
  FaFacebook,
  FaInstagram,
  FaWhatsapp
} from "react-icons/fa";
import profile from "../assests/Screenshot_2.png";
import { Collapse } from 'react-collapse';
import testimonialsData from "../Data/HomeData";
import { Link } from "react-router-dom";
import Footer from "./Footer";

//Images Used in website for team
import Image from '../assests/Screenshot_4.png'
import Founder from '../assests/img.jpg'
import HamzaImage from '../TeamData/HamzaShahid.jpeg'
import RaeesImage from '../TeamData/raeesawan.jpeg'
import KamalImage from '../TeamData/KamalMughal.jpeg'
import HabibaImage from '../TeamData/habiba.png'
import ZeehanImage from '../TeamData/zeehan.jpeg'
import ZabiImage from '../TeamData/zabi.jpg'
const Home = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  // FaqQuestions

  const faqItems = [
    { question: 'What services does CodeHub provide?', answer: 'We provide a wide range of web development services...' },
    { question: 'What method has CodeHub used for processing any service?', answer: 'We use a variety of techniques based on the service type...' },
    { question: 'How experienced is CodeHub in web development?', answer: 'CodeHub has over 10 years of experience in web development...' },
  ];
  

  
  //For Technologies
  const technologies = [
    {
      id: 1,
      title: "Front End Development",
      description: "React Js → Next Js → React Native → HTML/CSS",
      icon: <FaReact className="icon" />,
    },
    {
      id: 2,
      title: "Backend",
      description: "Node Js → Express Js → PHP",
      icon: <FaNodeJs className="icon" />,
    },
    {
      id: 3,
      title: "Database",
      description: "MongoDB → PostgreSQL → MySQL",
      icon: <FaDatabase className="icon" />,
    },
    {
      id: 4,
      title: "WordPress",
      description: "Create Dynamic and Engaging Websites with Ease",
      icon: <FaWordpress className="icon" />,
    },
    {
      id: 5,
      title: "Shopify",
      description: "Create Dynamic and Engaging Websites with Ease",
      icon: <FaShopify className="icon" />,
    },
    {
      id: 6,
      title: "Wix",
      description: "Custom Wix Websites",
      icon: <FaWix className="icon" />,
    },
    {
      id: 6,
      title: "Designing",
      description: "Figma ➜ XD ➜ Photoshop ➜ Illustrator",
      icon: <FaWix className="icon" />,
    },
  ];


  // Slider settings for testimonials
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// AOS Animation Initialization
useEffect(() => {
  AOS.init({
    duration: 1000, 
    offset: 50, 
    easing: "ease-in-out",
  });
  AOS.refresh();
}, []);

  const specialText = {
    special: "<Anas Saleem/>",
    codeHub: "<CodeHub/>",
  };

  return (
    <>
       <div className="home-container">
        <div className="home-content">
          {/* Main Content (Text) */}
          <div className="home-text" data-aos="fade-right" data-aos-duration="1000">
            <h1 className="home-title fade-in-animation">
              <TypeAnimation
                sequence={[`Welcome to ${specialText.codeHub}`]}
                speed={50}
                deletionSpeed={30}
                repeat={0}
              />
            </h1>

            <p className="home-subtitle fade-in-animation">
              We Build{" "}
              <span className="highlight">
                <TypeAnimation
                  sequence={["A creative Websites & Apps", 2000]}
                  speed={40}
                  deletionSpeed={30}
                  repeat={0}
                />
              </span>
            </p>
            {/* Description */}
            <p>
              We are a top IT{" "}
              <spann style={{ color: "#10b981" }}>{specialText.codeHub}</spann>{" "}
              company that specializes in offering personalized solutions for
              businesses of all sizes. Using our expertise in the latest
              technologies, we help businesses thrive in today's digital world.
              Let us introduce the products and services we offer to support
              your success.
            </p>
            <button className="home-button fade-in-animation">
            <Link to="/about" style={{color:"white",textDecoration:"none"}} >Learn More</Link>
            </button>
          </div>

          {/* Aside with Image */}
          <aside className="home-aside fade-in-animation" data-aos="fade-left" data-aos-duration="1000">
            <img src={profile} alt="Profile" className="aside-image" />
          </aside>
        </div>
      </div>

      {/* // Hero Section */}
      {/* Cards For Team */}

      {/* Card1 */}
      
 <section className="team-section">
        <div className="container fade-in-animation">
          <h2>Meet Our Team</h2>
          <div className="team-grid">
              <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={Founder}
                  alt='Anas Saleem'
                  className="member-image"
                />
                <h3>Anas Saleem</h3>
                <p>Full stack website and mobile app developer📱</p>
              </div>


              {/* card2 */}



              <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={ZabiImage}
                  alt='Zohaib Shabbir'
                  className="member-image"
                />
                <h3>Zohaib Shabbir</h3>
                <p>Front-End Developer  <div className="service-icon"><FaReact className="icon" size={40} color="blue" /></div></p>
              </div>

        
           {/* card3 */}


           <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={HabibaImage}
                  alt='Habiba Mughal'
                  className="member-image"
                />
                <h3>Habiba Mughal</h3>
                <p>UI&UX Designer ✨ </p>
              </div>



 {/* card4 */}

 <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={ZeehanImage}
                  alt='Zeehan Saqib'
                  className="member-image"
                />
                <h3>Zeehan Saqib</h3>
                <p>Seo Expert 📈 </p>
              </div>
{/* card5 */}
<div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={HamzaImage}
                  alt='Hamza Shahid'
                  className="member-image"
                />
                <h3>Hamza Shahid </h3>
                <p>Google ads manager || Digital Marketing Expert 📊 </p>
              </div>
              {/* card6 */}
              <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={RaeesImage}
                  alt='Raees Awan'
                  className="member-image"
                />
                <h3>Raees Awan</h3>
                <p>React-Native Developer || Mobile Application Developer📱 </p>
              </div>

              {/* Card7 */}

              <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src='https://i.pinimg.com/474x/48/98/5c/48985cc83ac4ecad753a9a292256e5d9.jpg'
                  alt='Zohaib Shabbir'
                  className="member-image"
                />
                <h3>Josefina Molly</h3>
                <p>Video Editor 🎬</p>
              </div>

              {/* card8 */}

              <div
              data-aos="fade-up" data-aos-duration="1000"
                className="team-member"
              >
                <img
                  src={KamalImage}
                  alt='Kamal Mughal'
                  className="member-image"
                />
                <h3>Kamal Mughal</h3>
                <p>Graphic Designer 🎨</p>
              </div>

          </div>
        </div>
      </section>
      

      {/* Services Section */}
      <section className="services-section fade-in-animation">
        <h2 className="services-title">Services</h2>
        <div className="services-container">
            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon"><FaReact className="icon" /></div>
              <h3 className="service-title">Front End Development"</h3>
              <p className="service-description">React Js → Next Js → React Native → HTML/CSS</p>
              <Link to="/WebDev"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">📱</div>
              <h3 className="service-title">App Development</h3>
              <p className="service-description">With the help of our specialized mobile app development services, stay ahead of the mobile revolution.</p>
              <Link to="/AppDev"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">🎨</div>
              <h3 className="service-title">Graphic Designing</h3>
              <p className="service-description">Creating a unique visual identity is essential for success and popularity.</p>
              <Link to="/GraphicDesign"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">✨</div>
              <h3 className="service-title">UI/UX Design</h3>
              <p className="service-description">Our primary goal is to provide engaging user experiences.</p>
              <Link to="/UiUx"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">🎮</div>
              <h3 className="service-title">Game Development</h3>
              <p className="service-description">Unlock the world of interactive entertainment with our cutting-edge game development services.</p>
              <Link to="/GameDev"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">🎬</div>
              <h3 className="service-title">Video Editing</h3>
              <p className="service-description">With our skilled video editing services, you can bring your vision to life.</p>
              <Link to="/VideoEditing"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">📈</div>
              <h3 className="service-title">SEO</h3>
              <p className="service-description">Boost your online visibility and drive organic traffic with our result-oriented SEO strategies.</p>
              <Link to="/seo"className="learn-more-btn">Learn More</Link>
            </div>

            <div className="service-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="service-icon">📊</div>
              <h3 className="service-title">Digital Marketing</h3>
              <p className="service-description">With our integrated digital marketing solutions, you may increase your online presence and accelerate business growth.</p>
              <Link to="/DigitalMarketing"className="learn-more-btn">Learn More</Link>
            </div>
          </div>
      </section>

      {/* About Us */}

      <section className="about-us-section fade-in-animation">
        <div className="about-us-container">
          <div className="about-us-image">
            <img
            data-aos="fade-right" data-aos-duration="2000"
              src={Image}
              alt="About Us"
            />
          </div>
          <div className="about-us-content" data-aos="fade-left">
            <h5 className="about-us-subtitle">About Us</h5>
            <h2 className="about-us-title">
              Innovating Solutions for a <span>Brighter Tomorrow</span>
            </h2>
            <p className="about-us-description">
              <span style={{ color: "#10b981" }}>{specialText.codeHub}</span> is
              a leading technology solutions provider committed to transforming
              ideas into reality. We offer cutting-edge software development,
              mobile app development, and digital transformation services that
              empower businesses to thrive in the digital world. With a focus on
              quality and innovation, our expert team is dedicated to delivering
              custom solutions that meet your unique needs and drive long-term
              success.
            </p>
            <Link to="/about" className="about-us-btn">Learn More</Link>
          </div>
        </div>
      </section>

      {/* Technologies Section */}
      <section className="technologies-section fade-in-animation">
      <div className="technologies-container">
        <div className="technologies-content">
          <h2 className="technologies-title">Technologies We Use</h2>
          <p className="technologies-description">
          <span style={{ color: "#10b981" }}>{specialText.codeHub}</span> leverages a variety of cutting-edge technologies to develop and deliver high-quality software. Our expertise spans multiple domains and industries, enabling us to select the most suitable technologies based on our clients' specific needs and project requirements.
          </p>
        </div>
        <div className="technologies-grid">
          {technologies.map((tech) => (
            <div key={tech.id} className="technology-card" data-aos="fade-left" data-aos-duration="1000">
              <div className="technology-icon">{tech.icon}</div>
              <h3 className="technology-title">{tech.title}</h3>
              <p className="technology-description">{tech.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

    {/* Faq Container */}
    <div className="faq-container">
      <div className="left-panel" data-aos="fade-right" data-aos-duration="1000">
        <button
          className={`button ${activeIndex === 0 ? 'active' : ''}`}
          onClick={() => toggleItem(0)}
        >
          General Questions
        </button>
        <button
          className={`button ${activeIndex === 1 ? 'active' : ''}`}
          onClick={() => toggleItem(1)}
        >
          Community
        </button>
        <button
          className={`button ${activeIndex === 2 ? 'active' : ''}`}
          onClick={() => toggleItem(2)}
        >
          Support
        </button>
      </div>

      <div className="right-panel" data-aos="fade-left" data-aos-duration="1500">
        {faqItems.map((item, index) => (
          <div className="faq-item" key={index}>
            <div className="question" onClick={() => toggleItem(index)}>
              {item.question}
            </div>
            <Collapse isOpened={activeIndex === index}>
              <div className="answer">{item.answer}</div>
            </Collapse>
          </div>
        ))}
      </div>
    </div>


{/* testimonial */}
<div className="testimonials-section">
  <h2 className="testimonials-title">
    What Our <span style={{ color: "#ff408c" }}>Customer</span> Says About Us
  </h2>
  <p className="testimonials-subtitle">
    Our clients are highly satisfied with our services, and they say choosing this IT company has been one of the best decisions for their organization.
  </p>
  <h3 className="customers-count" data-aos="fade-left">200+ Happy Customers and Still Counting</h3>
  <div className="testimonials-slider" data-aos="fade-right" data-aos-duration="1000">
    <Slider {...sliderSettings}>
      {testimonialsData.map((testimonial, index) => (
        <div key={index} className="testimonial-card">
          <img
            src={testimonial.avatar}
            alt={testimonial.name}
            className="testimonial-avatar"
          />
          <h4 className="testimonial-name">{testimonial.name}</h4>
          <p className="testimonial-country">{testimonial.country}</p>
          <p className="testimonial-text">{testimonial.text}</p>
        </div>
      ))}
    </Slider>
  </div>
</div>

    {/* Meet the Founder */}
    <section className="meet-the-founder">
      <div className="founder-container">
        <div className="founder-info">
          <h2>Meet the Founder</h2>
          <div className="founder-image" data-aos="fade-left">
            <img src={Founder} alt="Founder" />
          </div>
          <h3 data-aos="fade-right" data-aos-duration="1000">Anas Saleem</h3>
          <p data-aos="fade-up" data-aos-duration="1000">
            Anas Saleem is the visionary behind our company, bringing over 4 years of experience in web and app development. With a passion for innovative solutions and a drive for excellence, Anas founded our company with the goal of delivering top-notch software solutions to clients around the world.
          </p>
          <p data-aos="fade-left" data-aos-duration="1000">
            Under Anas's leadership, we have grown into a team of dedicated professionals committed to pushing the boundaries of technology and delivering exceptional value to our clients.
          </p>
          <Link to="/contact" className="contact-link" data-aos="fade-up" data-aos-duration="1000">Contact Anas</Link>
          <div className="social-media" data-aos="fade-up" data-aos-duration="1000">
            <a href="https://www.linkedin.com/in/dailycode01/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin />
            </a>
            <a href="https://github.com/awananas929" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
              <FaGithub />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100092881140503" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com/anassaleem.official/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="https://wa.me/+923161971274" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </section>
      <Footer/>
    </>
  );
};

export default Home;