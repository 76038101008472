
import React, { useEffect } from "react";
import "../Styling/About.css";
import Footer from './Footer'
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  const specialText = {
    special: "<Anas Saleem/>",
    codeHub: "<CodeHub/>",
  };

  // Aos Animation
useEffect(() => {
  AOS.init({
    duration: 1000,
  });
  AOS.refresh();
 
}, []);
  return (
    <>
    <section className="about-page">
      <div className="about-container">
        <div className="about-header">
          <h1>About {specialText.codeHub}</h1>
          <p>Leading provider of innovative software solutions since 2020.</p>
        </div>
        <div className="about-content">
          <div className="about-section" data-aos="fade-left">
            <h2>About Us</h2>
            <p>
              We are {specialText.codeHub}, a leading provider of innovative
              software solutions. Since our establishment in 2020, we have been
              dedicated to providing cutting-edge technology to businesses
              around the globe. Our strong team of skilled professionals strive
              to deliver unique, high-quality, and reliable software solutions
              that can help to transform and streamline business processes
              across a wide range of industries.
            </p>
            <p>
              We specialize in designing and implementing customized software
              solutions, tailored to each of our clients' unique requirements
              and business objectives. Our expertise spans across multiple areas
              including cloud computing, cybersecurity, AI and machine learning,
              data analysis, and software development and integration. We are
              passionate about keeping pace with the rapidly evolving technology
              landscape, and we are committed to delivering the most advanced
              solutions to our customers.
            </p>
          </div>
          <div className="about-section" data-aos="fade-right">
            <h2>Our Approach</h2>
            <p>
              At {specialText.codeHub}, we believe in forging lasting
              relationships with our clients, based on trust and mutual growth.
              We work collaboratively with them, understanding their unique
              business contexts and challenges, and developing solutions that
              not only address their immediate requirements but also provide
              them with the competitive edge in their respective markets. Our
              iterative development process, guided by Agile methodologies,
              ensures that our clients are always involved and satisfied with
              the progress.
            </p>
          </div>
          <div className="about-section" data-aos="fade-left">
            <h2>Our Mission</h2>
            <p>
              Our mission is to empower businesses through innovative technology
              solutions. We strive to revolutionize the way organizations
              operate, providing them with the tools they need to enhance
              productivity, drive efficiency, and realize their strategic
              objectives.
            </p>
          </div>
          <div className="about-section" data-aos="fade-right">
            <h2>Our Vision</h2>
            <p>
              We envision a world where technology is accessible and effectively
              leveraged by all businesses, regardless of their size or industry.
              By continuously developing our expertise and delivering
              exceptional software solutions, we aim to contribute to this
              vision and drive the digital transformation journey of businesses
              worldwide.
            </p>
          </div>
        </div>
        <div className="contact-footer">
          <p>
            Thank you for taking the time to learn more about us. Please don’t
            hesitate to get in touch if you have any questions or if you would
            like to discuss how our software solutions could benefit your
            business.
          </p>
        </div>
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default About;
