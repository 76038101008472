import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assests/logo.webp'
import '../Styling/NavMenu.css'
const NavMenu = () => {
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setIsMobile(!isMobile);
  };
    // Function to close the menu when clicking a link
    const closeMobileMenu = () => {
      setIsMobile(false);
    };
    
    
  return (
    <nav className="navbar">
    <div className="navbar-container">
      <div className="logo">
        {/* Replace with your logo */}
        <img src={logo} alt="Logo" className="logo-img" />
      </div>

      {/* Regular navigation links */}
      <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
        <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
        <li><Link to="/about" onClick={closeMobileMenu}>About</Link></li>
        <li className="services">
        <Link to="/services" onClick={closeMobileMenu}>Services</Link>
        </li>
        <li><Link to="/portfolio" onClick={closeMobileMenu}>Portfolio</Link></li>
        <li><Link to="/contact" onClick={closeMobileMenu}>Contact</Link></li>
      </ul>

      {/* Pricing button (visible on desktop) */}
      <div className="explore-button">
        <Link to="/ServiceSelection" className="Link">Explore Pricing</Link>
      </div>

      {/* Hamburger Icon */}
      <div className="hamburger" onClick={toggleMenu}>
        {isMobile ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </div>
    </div>
  </nav>
  );
};

export default NavMenu;
