import React from 'react';
import { FaBullhorn } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const digitalMarketingImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6z3mxUipSmMI5AqXnHcYDJOCUwvF9n3Yp1g&s";

const DigitalMarketing = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaBullhorn className="service-icon" />
        <h3>Digital Marketing</h3>
      </div>
      <img src={digitalMarketingImage} alt="Digital Marketing" className="service-image" />
      <div className="service-content">
        <p>
          Our digital marketing services are designed to help you build your brand, engage your audience, and grow your business. From SEO to PPC, we ensure you reach your target customers and achieve measurable results.
        </p>
        <p><strong>What We Offer:</strong></p>
        <ul className="service-list">
          <li>Search Engine Optimization (SEO)</li>
          <li>Social Media Marketing (SMM)</li>
          <li>Pay-Per-Click Advertising (PPC)</li>
          <li>Email Marketing Campaigns</li>
          <li>Content Marketing and Strategy</li>
          <li>Conversion Rate Optimization (CRO)</li>
          <li>Online Reputation Management (ORM)</li>
        </ul>
        <p>
          By combining strategy, creativity, and data-driven decisions, we maximize your digital marketing efforts and ensure you get the best return on your investment.
        </p>
      </div>
    </div>
<Footer/>
    </>
  );
};

export default DigitalMarketing;
