import React from 'react';
import { FaSearch } from 'react-icons/fa';
import '../Styling/ServiceCard.css';
import Footer from '../Components/Footer';

const seoImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfT4kr7sc6WUYuWN1OjTu3v34IXFRY7Lk-QA&s";

const SEO = () => {
  return (
    <>
    <div className="service-card1">
      <div className="service-header">
        <FaSearch className="service-icon" />
        <h3>Search Engine Optimization (SEO)</h3>
      </div>
      <img src={seoImage} alt="SEO" className="service-image" />
      <div className="service-content">
        <p>
          Our expert SEO services help your website rank higher on search engines, attract organic traffic, and boost your online presence. We utilize the latest SEO strategies to improve your site's visibility and ensure it reaches the right audience.
        </p>
        <p>
          <strong>What We Offer:</strong>
        </p>
        <ul className="service-list">
          <li>Comprehensive SEO audits</li>
          <li>Keyword research and analysis</li>
          <li>On-page optimization (title tags, meta descriptions, headers, etc.)</li>
          <li>Off-page optimization (backlink building, social signals, etc.)</li>
          <li>Local SEO for location-based services</li>
          <li>SEO content creation and optimization</li>
          <li>Regular performance tracking and reporting</li>
        </ul>
        <p>
          Whether you’re looking to dominate search rankings locally or globally, our team ensures your business stays ahead of the competition with a solid SEO strategy.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default SEO;
